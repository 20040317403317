import React from 'react';
import { graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Card, Pagination } from 'antd';

import Layout from '../components/layout';
import SEO from '../components/seo';

import primeLogo from '../../static/logo.png';

const InsightsWrapper = styled.div`
  overflow: hidden;
  max-width: 1366px;
  margin: auto;
  padding: 80px 20px;
  padding-top: 160px;

  .title {
    font-weight: 600;
    margin-bottom: 24px;
    font-size: 60px;
    line-height: 60px;
  }
  .insight-links {
    display: inline-block;
    padding: 4px 12px;
    font-size: 22px;
    line-height: 22px;
    background-color: #eeeeee;
    margin: 4px;
  }
  .top-row {
    margin-bottom: 60px;
  }
  .insight-links.active {
    font-weight: 600;
    text-decoration: underline;
    background-color: ${props => props.theme.primeYellowPalette.original};
    color: ${props => props.theme.primeBluePalette.original};
  }

  .insight-card {
    height: 100%;
    border: 0;
    .ant-card-body {
      padding: 0px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    img {
      margin-bottom: 18px;
    }
  }
  .insight-title {
    font-size: 20px;
    line-height: 22px;
  }
  .insight-section {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 10px;
    margin: 0;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .insight-content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .insight-date {
    color: #aaaaaa;
    font-size: 12px;
    line-height: 14px;
  }
  .cta-link {
    margin-top: auto;
    margin-left: auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  .insight-card-alt {
    height: 100%;
    .ant-card-body {
      padding: 0;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0;
    }

    .card-overlay {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 20px;
      background-color: rgba(0, 51, 102, 0.7);
      color: white;

      .cta-link > a,
      .insight-title {
        color: ${props => props.theme.primeYellowPalette.original} !important;
      }
      .insight-title {
        font-size: 32px;
        line-height: 32px;
      }
      .insight-section {
        /* text-transform: uppercase;
        font-size: 10px;
        line-height: 10px;
        margin: 0;
        font-weight: 600;
        letter-spacing: 1px; */
        margin-bottom: 4px;
      }
    }
  }
`;

/**
 * Do take note this page covers not just the '/insights' route, but also should
 * support other tags put into the next param e.g. '/insights/covid-19'
 * Tentatively using createPage in gatsby-node.js to build those pages as well
 */

const links = [
  { text: 'Latest Insights', link: '/insights' },
  { text: 'COVID-19', link: '/insights/covid-19' },
  { text: 'Workplaces and Offices', link: '/insights/offices' },
  { text: 'Retail and Commerce', link: '/insights/retail' },
  { text: 'Industrial', link: '/insights/industrial' },
  { text: 'Hotel and Tourism', link: '/insights/hospitality' },
  { text: 'Tech and Innovation', link: '/insights/tech-and-innovation' },
  { text: 'Investments', link: '/insights/investments' },
  { text: 'Sustainability', link: '/insights/sustainability' },
];

function InsightsPage({ data, pageContext, location }) {
  const currentTopic = pageContext.currentTopic;

  // alternative card design vars
  let altDesignCounter = 0;
  let nextAltDesignIndex = 2;

  const onPageChange = page => {
    if (currentTopic !== 'Latest Insights') {
      navigate(`${pageContext.link}${page!==1 ? `/${page}`: ''}`);
    } else {
      navigate(`/insights${page!==1 ? `/${page}`: ''}`);
    }
  };

  return (
    <Layout>
      <SEO
        title="Industry Insights"
        description="Get the latest insider information and expert opinion on Philippine real estate industry here."
        meta={[
          {
            property: 'og:image',
            content: primeLogo,
          },
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content: "Commercial Real Estate Service Provider in the Philippines, Commercial Real Estate Service Firm in the Philippines, Commercial Real Estate Leasing Firm  in the Philippines, Commercial Real Estate Advisory Firm in the Philippines, Commercial Real Estate Consultancy Firm in the Philippines, The  Leading Commercial Real Estate Consultancy Firm in the Philippines, The Leading Commercial Real Estate Leasing Agency in the Philippines, The Leading Commercial Real Estate Advisory  Firm in the philippines, The Leading Commercial Real Estate Service Firm  in the Philippines, The Leading Commercial Real Estate Service Provider  in the Philippines, Industrial Leasing Solutions in the Philippines, Warehouse Leasing Solutions in the Philippines, Real Estate Leasing Solutions in the Philippines, Retail and Office Leasing Solutions in the Philippines, Retail Leasing Solutions in the Philippines, Real Estate Brokerage Leasing Solutions in the Philippines, Real Estate Research and Advisory Solutions in the Philippines, Property Research and Advisory Solutions in the Philippines, Property and Facilities Management Solutions in the Philippines, Property and Facilities Management Solutions in the Philippines, Property and Facilities Management Solutions in the Philippines, Industrial Warehouse leasing, Office Leasing Solution, Retail Leasing Solution, Real Estate Research and Advisory Solutions, Real Estate Brokerage Leasing, Real Estate Leasing Solutions, Property Leasing Services",
          },
        ]}
      />
      <InsightsWrapper>
        <Row className="top-row" type="flex" justify="center">
          <Col xs={24} sm={24} md={24} lg={24}>
            <h1 className="title">Industry Insights</h1>
            {links.map(data => (
              <Link
                className={`insight-links ${
                  currentTopic === data.text ? 'active' : ''
                }`}
                to={data.link}
                key={data.text}
              >
                {data.text}
              </Link>
            ))}
          </Col>
        </Row>
        <Row gutter={[15, 40]} type="flex" justify="center">
          {data.allStrapiRealEstateInsight.nodes.map((insight, index) => {
            let applyAltDesign = false;
            if (index === nextAltDesignIndex) {
              applyAltDesign = true;
              altDesignCounter += 1;
              if (altDesignCounter === 3) {
                nextAltDesignIndex += 5;
                altDesignCounter = 0;
              } else {
                nextAltDesignIndex += 2;
              }
            }
            return applyAltDesign ? (
              <Col
                key={insight.strapiId}
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                <Card className="insight-card-alt">
                  <img
                    alt={`${insight.title}`}
                    src={`${process.env.IMAGE_ASSETS}/real-estate-insights/${insight.strapiId}/thumb/${insight.thumbnail}`}
                  />
                  <div className="card-overlay">
                    {insight.section ? (
                      <p className="insight-section">{insight.section}</p>
                    ) : (
                      ''
                    )}
                    <Link
                      to={`/insights/articles/${insight.url_link}`}
                    >
                      <h3 className="insight-title">{insight.title}</h3>
                    </Link>
                    <div className="cta-link">
                      <Link
                        to={`/insights/articles/${insight.url_link}`}
                      >
                        READ MORE <FontAwesomeIcon icon="angle-right" />
                      </Link>
                    </div>
                  </div>
                </Card>
              </Col>
            ) : (
              <Col
                key={insight.strapiId}
                xs={24}
                sm={24}
                md={12}
                lg={6}
                xl={6}
                xxl={6}
              >
                <Card className="insight-card">
                  <img
                    alt={`${insight.title}`}
                    src={`${process.env.IMAGE_ASSETS}/real-estate-insights/${insight.strapiId}/thumb/${insight.thumbnail}`}
                  />
                  {insight.section ? (
                    <p className="insight-section">{insight.section}</p>
                  ) : (
                    ''
                  )}
                  <Link
                    to={`/insights/articles/${insight.url_link}`}
                  >
                    <h3 className="insight-title">{insight.title}</h3>
                  </Link>
                  <p className="insight-date">
                    {insight.published_date} • {insight.author}
                  </p>
                  <div className="insight-content">{insight.description}</div>
                  <div className="cta-link">
                    <Link
                      to={`/insights/articles/${insight.url_link}`}
                    >
                      READ MORE <FontAwesomeIcon icon="angle-right" />
                    </Link>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
        <Row className="top-row" type="flex" justify="center">
          <Col xs={24} sm={24} md={24} lg={24}>
            <Pagination
              current={pageContext.currentPage}
              defaultPageSize={12}
              total={pageContext.total}
              showSizeChanger={false}
              onChange={onPageChange}
            />
          </Col>
        </Row>
      </InsightsWrapper>
    </Layout>
  );
}

export default InsightsPage;

export const pageQuery = graphql`
  query insightPageQuery($skip: Int!, $limit: Int!, $filter: String!) {
    allStrapiRealEstateInsight(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: published_date }
      filter: { tags: { glob: $filter } }
    ) {
      nodes {
        author
        description
        strapiId
        url_link
        title
        tags
        thumbnail
        section
        published_date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
